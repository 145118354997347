<template>
  <div
    v-bind="$attrs"
    :class="{
      'no-relatives': isOptionType && !question.options && visible,
      'only-field':
        isOptionType && !question.options && visible && !!+question.value,
      'input-block':
        ((isLineType && isBase) || (isDateType && isBase)) && visible,
    }"
  >
    <select-input
      v-if="isDropdownType && visible && isGroup"
      :id="question.id"
      :options="question.options"
      :required="required"
      :invalid="errors[question.id] && !question.value"
      @input="$emit('selectHandler', question, $event)"
      :value="question.value"
    >
      <div slot="label" v-html="question.name"></div>
    </select-input>
    <text-input
      v-else-if="(isLineType || isDateType) && isBase && visible"
      :id="question.id"
      :type="isDateType ? 'date' : 'text'"
      :required="required"
      :mask="question.input_mask"
      :invalid="
        (errors[question.id] && !question.value) || invalidSymbolsLength
      "
      :value="question.value"
      :tooltip="question.placeholder"
      @input="$emit('inputHandler', { question, $event })"
    >
      <div slot="label" v-html="question.name"></div>
    </text-input>
    <text-area
      v-else-if="isTextType && isBase && visible"
      :id="question.id"
      :required="required"
      :invalid="
        (errors[question.id] && !question.value) || invalidSymbolsLength
      "
      :limit="question.answer_length || ''"
      :value="question.value"
      :tooltip="question.placeholder"
      @input="!$emit('inputHandler', { question, $event })"
    >
      <div slot="label" v-html="question.name"></div>
    </text-area>
    <text-area
      v-else-if="isTextType && isComment && visible && !!question.value"
      :value="question.value"
      :disabled="isComment"
      class="is-comment"
      :class="question.value ? 'has-comment-value' : ''"
    >
    </text-area>
    <template
      v-else-if="
        isOptionType && visible && (!question.subquestions || !question.options)
      "
    >
      <custom-checkbox
        v-if="
          !question.options ||
          (question.options && question.options.length == 1)
        "
        :id="question.id"
        :required="required"
        :invalid="errors[question.id] && !question.value"
        :value="checkboxValue"
        @change="$emit('checkboxHandler', question, $event)"
      >
        {{ question.name }}
      </custom-checkbox>
      <template v-else>
        <div class="subtitle" v-html="question.name"></div>
        <radio
          :id="question.id"
          :value="question.value"
          :options="question.options"
          @change="(val) => $emit('radioHandler', { question, val })"
        />
      </template>
    </template>
    <file-el
      v-else-if="isFileType && visible && !isFileDownloadType"
      :id="question.id"
      :name="question.name"
      :required="required"
      :invalid="errors[question.id] && !question.value"
      :value="question.value || []"
      @upload-files="(p) => $emit('upload-files', p, question)"
      @update-files="(p) => $emit('update-files', question, p)"
      @set-empty-error="(p) => $emit('updateEmptyError', p, question)"
      @set-size-error="(p) => $emit('updateSizeError', p)"
    />
    <custom-button
      v-else-if="isFileDownloadType && visible"
      type="button"
      class="blue"
      :disabled="!question.get"
    >
      <Icon iconName="upload" iconColor="#0075DB" />
      <a :href="`/proxy.php?${question.get}`">
        {{ question.name || '[без имени]' }}
      </a>
    </custom-button>
    <scale
      v-else-if="isScaleType && visible"
      :model="+question.value"
      :required="required"
      @change="(p) => $emit('radioHandler', { question, val: p })"
    >
      <div slot="label" v-html="question.name"></div>
    </scale>
  </div>
</template>
<script>
import TextInput from '@/components/elements/TextInput'
import TextArea from '@/components/elements/TextArea'
import SelectInput from '@/components/elements/SelectInput'
import CustomCheckbox from '@/components/elements/Checkbox'
import Radio from '@/components/elements/Radio'
import FileEl from '@/components/elements/File'
import CustomButton from '@/components/elements/Button'
import Icon from '@/components/elements/Icon'
import Scale from '@/components/elements/Scale'
export default {
  inheritAttrs: false,
  props: {
    question: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    requirementData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isBase() {
      return this.question.question_type == 'base'
    },
    isComment() {
      return this.question.question_type == 'inspector_comment'
    },
    isFileType() {
      return this.question.answer_type === 'file_answer'
    },
    isFileDownloadType() {
      return this.isFileType && this.question.question_type === 'file'
    },
    isOptionType() {
      return this.question.answer_type == 'option_answer'
    },
    isLineType() {
      return this.question.answer_type == 'line_answer'
    },
    isTextType() {
      return this.question.answer_type == 'text_answer'
    },
    isDateType() {
      return this.question.answer_type == 'date_answer'
    },
    isDropdownType() {
      return this.question.answer_type == 'dropdown_answer'
    },
    isGroup() {
      return this.question.question_type == 'group'
    },
    isScaleType() {
      return this.question.answer_type == 'scale_answer'
    },
    visible() {
      return !this.question.hide && !this.question.hideAll
    },
    required() {
      return (
        this.requirementData.includes(
          this.question.etalon_id || this.question.id
        ) || this.question.required == '1'
      )
    },
    invalidSymbolsLength() {
      return (
        !!this.question.value &&
        !!this.question.answer_length &&
        this.question.value.length < this.question.answer_length
      )
    },
    checkboxValue() {
      return this.question.required == '1'
        ? true
        : !!this.question.value &&
            this.question.value !== '0' &&
            this.question.value !== 'Нет'
    },
  },
  components: {
    CustomCheckbox,
    TextInput,
    TextArea,
    SelectInput,
    Radio,
    FileEl,
    CustomButton,
    Icon,
    Scale,
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/questionnaire.scss';

.block-title {
  color: $black-200;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
}

.no-relatives:not(.only-field) {
  margin-top: 32px;
}

.is-comment {
  pointer-events: none;

  &.has-comment-value ::v-deep textarea {
    border: 1px solid $orange !important;
  }
}
</style>
