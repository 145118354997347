<template>
  <!-- <div
    :class="{
      'is-required': required,
    }"
  > -->
  <div class="wrapper">
    <div v-if="$slots.label" class="label-wrapper">
      <label class="label" :for="id">
        <slot name="label"></slot>
      </label>
    </div>
    <el-popover trigger="focus" placement="right-end" popper-class="tooltip">
      <el-input
        v-bind="$attrs"
        v-model="val"
        :id="id"
        :required="$attrs.required"
        :placeholder="placeholder"
        :disabled="$attrs.disabled"
        slot="reference"
        :minlength="limit"
        :maxlength="limit"
        type="textarea"
        v-on="{ ...$listeners, input: () => {} }"
        autosize
        class="textarea el-textarea--resize-icon"
        :class="[classes, invalid && 'error']"
      />
      <div
        class="tooltip"
        v-if="invalid && tooltipText && limit"
        v-html="tooltipText"
      ></div>
    </el-popover>
    <!-- <p v-if="invalid" class="error-message">
      <slot name="error">Проверьте правильность заполнения поля</slot>
    </p> -->
  </div>
</template>

<script>
import { Input } from 'element-ui'
import { Popover } from 'element-ui'
export default {
  name: 'TextArea',
  inheritAttrs: false,
  components: { 'el-popover': Popover, 'el-input': Input },
  props: {
    classes: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    tooltip: { type: String, default: 'ошибка количества символов' },
    limit: {
      type: [Number, String],
      default: '',
    },
  },
  computed: {
    val: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    tooltipText() {
      return this.limit && this.invalid
        ? `Допустимое количество символов: ${this.limit}. Длина текста сейчас: ${this.val.length}`
        : ''
    },
  },
}
</script>
<style lang="scss" scoped>
.tooltip {
  background: black;
  color: white;
  padding: 2px 6px;
}

.el-textarea {
  width: 480px;

  & ::v-deep &__inner {
    border-radius: 4px;
    color: $black-200;
    border: 1px solid $black-30;
    font-family: 'Roboto', Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    min-height: 120px !important;
    -ms-overflow-style: none;
    position: relative;
    word-break: break-word;
    padding-left: 12px !important;

    &:hover {
      border-color: $black-100;
    }

    &:focus {
      border-color: $blue-120;
    }
  }

  &--resize-icon::after {
    content: '';
    width: 28px;
    height: 28px;
    display: inline-block;
    position: absolute;
    background-image: url('../../assets/img/resizer.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    bottom: 1px;
    right: 1px;
    background-color: $white;
    border-radius: 4px;
    pointer-events: none;
  }

  &--resize-icon .el-textarea {
    resize: vertical;
  }

  &.is-disabled.el-textarea {
    opacity: 1;

    &--resize-icon::after {
      background-color: $black-10;
      background-image: url('/front/public/style/img/resizer-disabled.svg');
    }
  }

  &.is-disabled .el-textarea__inner {
    background-color: $black-10;
    border-color: $black-30;
    color: $black-80;
  }
}

.el-input {
  & ::v-deep &__inner {
    border-radius: 4px;
    color: $black-190;
    font-family: 'Roboto', sans-serif;
    min-height: 120px;
  }
}

.error {
  border: 0.5px solid #f1666a;
  color: #fb5a5a;
}
</style>
